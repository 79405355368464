<template>
  <div>
    <div class="events-title 1" v-if="selectedDate && !showDate">
      {{ formatDate(selectedDate, 'dddd, MMM D', 'MM-DD-YYYY') }}
    </div>
    <div v-for="(event, index) in calendarEventsList.slice(0, limit)" :key="index" class="events-list">
      <div class="event-date" v-if="showDate">
        <div class="month">
          {{ formatDate(new Date(event.eventDate), 'MMM') }}
        </div>
        <div class="date">
          {{ formatDate(new Date(event.eventDate), 'D') }}
        </div>
      </div>

      <a class="event-link" :href="event.detailURL" target="_blank" v-srOnlyNewWindow v-html="event.title"></a>
    </div>
  </div>
</template>
<script>
import { dateConvert } from '../../../utilities/filters.js'
export default {
  name: 'Calendar-events-list',
  data() {
    return {
      selectedDateFormatted: new Date(),
    }
  },
  methods: {
    formatDate(date, format, inputFormat) {
      return dateConvert(date, format, inputFormat)
    },
  },
  props: {
    calendarEventsList: {
      type: Array,
      default() {
        return []
      },
    },
    limit: [Number, String],
    showDate: { type: Boolean, default: false },
    showError: { type: Boolean, default: true },
    selectedDate: String,
  },
}
</script>

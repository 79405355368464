import { render, staticRenderFns } from "./CalendarEventsList.vue?vue&type=template&id=36c687d8&"
import script from "./CalendarEventsList.vue?vue&type=script&lang=js&"
export * from "./CalendarEventsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../CitizenPortalUI_Admin/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports